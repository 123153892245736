<template>
  <div class="row no-wrap justify-between">
    <q-card class="col-3 q-mr-md">
      <div>
        <div class="q-ma-md text-h6 text-secondary text-left text-bold my-font"><b>Top Asins:</b></div>
      </div>
      <div class="top5AsinsCard row wrap justify-center items-center content-center">
        <q-card
            class="col-5 my-font q-mx-sm q-my-md q-pa-sm shadow-6"
            :class="top4Colors[key]"
            v-for="(asin, key) of localTop4Asins"
            :key="key"
        >
          <div class="row">
            <div class=" text-subtitle1 q-mr-sm q-my-none"><b>{{ asin.asin }}</b></div>
            <q-btn disable flat dense icon="open_in_new"/>
          </div>

          <q-separator inset/>
          <div class="q-ml-xs text-subtitle2"><b>BSR:</b>{{ asin.bsr }}</div>
          <div class="q-ml-xs text-subtitle2"><b>Category:</b>{{ asin.category }}</div>
          <div class="q-ml-xs text-subtitle2"><b>Rating:</b>{{ asin.rating }}</div>
          <div class="q-ml-xs text-subtitle2"><b>Reviews:</b>{{ asin.reviews }}</div>
        </q-card>
      </div>
    </q-card>
<!--    TODO: make a blur charts template-->
    <q-card class="col-3 q-mr-md doughnutWrapper">
      <BlurDoughnutChart />
    </q-card>
    <q-card class="col-5 lineWrapper">
      <BlurMainLineChart />
    </q-card>
  </div>

</template>

<script>
import BlurDoughnutChart from "../blur-template/BlurDoughnutChart";
import BlurMainLineChart from "../blur-template/BlurMainLineChart";

export default {
  name: "BlueCharts",
  components: {BlurDoughnutChart,BlurMainLineChart},
  data() {
    return {
      localTop4Asins: [{
        asin: 'B089CBG8',
        bsr: '48,266',
        rating: '5',
        category: 'Toys & Games',
        reviews: '2'
      }, {
        asin: 'B08HCN9N',
        bsr: '8,384',
        rating: '4.8',
        category: 'Toys & Games',
        reviews: '707'
      }, {
        asin: 'B07P47LY',
        bsr: '695',
        rating: '4.8',
        category: 'Toys & Games',
        reviews: '6,384'
      }, {
        asin: 'B06GFL1XJ',
        bsr: '12,166',
        rating: '4.8',
        category: 'Toys & Games',
        reviews: '2,383'
      }],
      top4Colors: ['bg-blue-2', 'bg-purple-3', 'bg-teal-3', 'bg-grey-5']
    }
  }
}
</script>

<style scoped>

</style>