<template>
  <div class="bg-grey-3 row">
<!--    TODO: fix div jumping when switching to new market creation (might be the border for the button)-->
    <div class="q-mb-lg col-12">
      <NewMarketInput/>
    </div>
    <!--    <div class="q-mb-lg col-12">-->
    <!--      <NewMarketKeywords class="q-mx-lg"/>-->
    <!--    </div>-->
    <q-form
        class="col-12"
        disabled
    >
      <NewMarketsBlurTemplate/>
    </q-form>

  </div>
</template>

<script>

import NewMarketInput from "../../components/market-tracker/new-market-creation/NewMarketInput";
import NewMarketKeywords from "../../components/market-tracker/new-market-creation/NewMarketKeywords";
import NewMarketsBlurTemplate from "./NewMarketsBlurTemplate";
import Keywords from "../../components/market-tracker/table/table-components/Keywords";

export default {
  name: "CreateNewMarket",
  components: {Keywords, NewMarketsBlurTemplate, NewMarketKeywords, NewMarketInput}
}
</script>

<style lang="sass" scoped>

</style>