<template>
  <div class="row">
    <!--    filters-->
    <div class="q-mb-sm col-12">
      <q-btn-group class="bg-white">
        <q-btn class="bg-secondary" label="All" icon="apps" disable/>
        <q-btn label="Pending" icon="update" disable/>
        <q-btn label="Tracked" icon="visibility" disable/>
        <q-btn label="Ignored" icon="visibility_off" disable/>
      </q-btn-group>
      <q-card class="q-pa-md">
        <p>SORT BY : </p>
        <q-btn
            disable
            v-for="(button,key) of buttons"
            :key="key"
            class="q-mx-md"
            color="primary"
            :label="button"
            icon-right="arrow_drop_down"
        >
        </q-btn>
      </q-card>
    </div>
    <!--    filters    -->

    <!--    productInfo    -->
    <div class="q-mb-md col-12">
      <!--    pagination    -->
      <q-card flat class="row q-py-sm bg-transparent" style="width: fit-content; margin: 0 auto">
        <q-card flat class="q-mr-sm bg-transparent" style="margin-top: 4px">
          <q-pagination
              disable
              v-model="current"
              :max="10"
              :max-pages="6"
              boundary-numbers
          />
        </q-card>
        <q-separator vertical color="primary"/>
        <q-card flat class="bg-transparent" style="max-width: 200px">
          <q-btn flat class="q-mx-sm bg-transparent text-white" label="10" disable/>
          <q-btn flat class="q-mx-sm bg-primary text-white" label="25" disable/>
          <q-btn flat class="q-mx-sm bg-transparent text-white" label="50" disable/>
        </q-card>
      </q-card>
      <!--    pagination    -->
      <q-card
          v-for="(item, key) in blurData"
          :key="key"
          class="q-my-sm row"
      >
        <div class="col-12">
          <ProductTitle :product="item"/>

        </div>

        <div class="col-12 row">
          <MainDetails :product="item" class="col-4 my-product"/>
          <BlurProductLineChart class="col-4 my-product"/>
          <SalesDetails :product="item" class="col-4 my-product"/>
        </div>
      </q-card>
      <q-card flat class="row q-py-sm bg-transparent" style="width: fit-content; margin: 0 auto">
        <q-card flat class="q-mr-sm bg-transparent" style="margin-top: 4px">
          <q-pagination
              disable
              v-model="current"
              :max="10"
              :max-pages="6"
              boundary-numbers
          />
        </q-card>
      </q-card>


    </div>
    <!--    productInfo    -->

  </div>

</template>

<script>
import ProductTitle from "../market-tracker/table/table-components/product-info/ProductTitle";
import MainDetails from "../market-tracker/table/table-components/product-info/MainDetails";
import BlurProductLineChart from "../blur-template/BlurProductLineChart";
import SalesDetails from "../market-tracker/table/table-components/product-info/SalesDetails";

export default {
  name: "BlurTable",
  components: {SalesDetails, MainDetails, ProductTitle, BlurProductLineChart},
  data() {
    return {
      buttons: ['PRICE', 'BSR', 'REVIEW', 'BRAND', 'CATEGORY'],
      localMarketId: null,
      current: 6,
      myMarkets: [],
      /*pagination*/
      page: 1,
      currentPage: 1,
      blurData: {
        0: {
          demo: true,
          asin: "B08Y5QFF8",
          averageRatings: 4.8,
          brand: "Funko",
          bsr: 72187,
          category: "Toys & Games ",
          fulfillment: "fba",
          img: "https://m.media-amazon.com/images/I/516z6IVWisL._AC_UL320_.jpg",
          marketShare: "10",
          price: 29.99,
          revenue: 2760,
          sales: 92,
          salesShare: "60",
          seller: 13,
          subcategoryRank: 5618,
          title: "Funko POP! Animation #917 - Spongebob Weightlifter Exclusive",
          totalReviews: 69,
          tracked: false
        },
        1: {
          demo: true,
          asin: "B097TXFQH",
          averageRatings: 4.4,
          brand: "PokeC3%A9mon",
          bsr: 172187,
          category: "Toys & Games ",
          fulfillment: "amazon",
          img: "https://m.media-amazon.com/images/I/91H38kj+dUL._AC_SL1500_.jpg",
          marketShare: "25",
          price: 39.99,
          revenue: 5000,
          sales: 3332,
          salesShare: "70",
          seller: 1,
          subcategoryRank: null,
          title: "Hidden Fates Tin: Gyarados-GX",
          totalReviews: 821,
          tracked: true,
          userProduct: null
        },
        2: {
          demo: true,
          asin: "B0965S5QH",
          averageRatings: 4.6,
          brand: "Funko",
          bsr: 7287,
          category: "Toys & Games ",
          fulfillment: "fba",
          img: "https://m.media-amazon.com/images/I/51Q3oMQbZtS._AC_UL320_.jpg",
          marketShare: "50",
          price: 18,
          revenue: 8000,
          sales: 112,
          salesShare: "15",
          seller: 21,
          subcategoryRank: null,
          title: "Funko Marvel: Infinity Warps Pop! Weapon Hex Vinyl Figure Hot Topic Exclusive",
          totalReviews: 14,
          tracked: true,
          userProduct: null
        },
      }
    }
  }
}
</script>

<style scoped>

</style>