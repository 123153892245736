import { render } from "./BlurCharts.vue?vue&type=template&id=75d69579&scoped=true"
import script from "./BlurCharts.vue?vue&type=script&lang=js"
export * from "./BlurCharts.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-75d69579"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QBtn,QSeparator});
