import { render } from "./CreateNewMarket.vue?vue&type=template&id=0375c42e&scoped=true"
import script from "./CreateNewMarket.vue?vue&type=script&lang=js"
export * from "./CreateNewMarket.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-0375c42e"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm});
