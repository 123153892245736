import { render } from "./NewMarketInput.vue?vue&type=template&id=d6651d2c&scoped=true"
import script from "./NewMarketInput.vue?vue&type=script&lang=js"
export * from "./NewMarketInput.vue?vue&type=script&lang=js"

import "./NewMarketInput.vue?vue&type=style&index=0&id=d6651d2c&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-d6651d2c"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QCard});
