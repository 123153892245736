import { render } from "./NewMarketKeywords.vue?vue&type=template&id=21c3d06b&scoped=true"
import script from "./NewMarketKeywords.vue?vue&type=script&lang=js"
export * from "./NewMarketKeywords.vue?vue&type=script&lang=js"

import "./NewMarketKeywords.vue?vue&type=style&index=0&id=21c3d06b&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-21c3d06b"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QInput,QBtn});
