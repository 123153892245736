<template>
  <q-card class="q-py-xs row my-font">

      <q-card
          v-for="(view,key) of overviews"
          :key="key"
          flat class="col">
        <q-item class="q-ml-md">
          <q-item-section avatar class="avatarz">
            <q-avatar size="59px" font-size="55px" color="white" text-color="text-deep-purple-6" :icon="view[0]"/>
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-subtitle1"> {{ view[1] }}</q-item-label>
            <q-item-label class="text-h5 text-deep-purple-6 text-bold"> ${{ view[2] }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-card>

  </q-card>
</template>

<script>
export default {
  name: "BlurOverview",
  data() {
    return {
      overviews: {
        0: ['attach_money','REVENUE','3,569,152'],
        1: ['attach_money','SALES','270,310'],
        2: ['swap_vert','ROI/MARGIN','74.8%/14.2%'],
        3: ['notes','AVERAGE REVIEWS','450'],
      },
    }
  },
}
</script>

<style lang="sass" scoped>

.avatarz
  vertical-align: middle
  width: 70px
  height: 70px
  border-radius: 50%
  border: 6px solid #D1C4E9
  color: #D1C4E9
  margin-right: 5px

</style>
