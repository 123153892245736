<template>
  <div class="q-mx-lg">
    <!--      title input + buttons      -->
    <div class="row justify-between items-center q-mb-lg">
      <div class="q-mt-md bg-grey-3 row">
        <div class="q-pt-md text-h6 text-secondary" :class="textClasses">
          Market Name:
        </div>
        <div class="q-pl-sm" style="height: 25px" :class="textClasses">
          <q-input
              outlined
              style="width: 270px"
              v-model="marketName"
              label="Market Name"
          />
        </div>
        <div class="q-ml-sm wrap instruction my-font text-center text-bold">
          Please insert a Market-Tracker name in order to perform a research over specific keywords
        </div>
      </div>
      <div class="q-mt-md">
        <q-btn
            class="text-capitalize q-mr-md"
            icon-right="cancel"
            color="red-2"
            text-color="negative"
            style="width: 120px; height: 28px "
            to="/market-tracker"
            label="Cancel"/>
        <q-btn
            class="text-capitalize"
            icon-right="check_circle_outline"
            color="green-2"
            text-color="positive"
            style="width: 120px;height: 28px"
            @click="submit"
            label="Create"
        />
      </div>
    </div>
    <!--      title input + buttons      -->
    <!--    keywords input      -->
    <q-card class="q-pa-md">
      <p>Please add new keywords, up to 5 may be added.</p>
      <div class="row justify-start">
        <q-input
            borderless
            dense
            class="q-mx-md text-center text-no-wrap keyword"
            :class="keyword.selectedClass"
            v-for="(keyword, index) in keywordsOptions"
            :key="index"
            :color="keyword.color"
            :label="keyword.label"
            :disable="keyword.disable"
            :readonly="keyword.disable"
            v-model="keywords[index]"
        />
        <q-btn
            :disable="current >= 5"
            class="col bg-deep-orange-6 q-ml-md text-left text-no-wrap keyword new-keyword"
            style="width: 185px; max-width: 185px"
            color="white"
            icon-right="add_circle"
            label="NEW KEYWORD"
            glossy
            flat
            @click="addMoreKw"
        />
      </div>
    </q-card>
    <!--    keywords input      -->
  </div>
</template>

<script>

import serverApi from "../../../middleware/serverApi"

export default {
  name: "NewMarketInputs",
  data() {
    return {
      textClasses: 'text-left text-bold my-font',
      // inputs
      marketName: '',
      keywords: [],
      search: "",
      submitProcess: false,

      // keywords styles
      keywordsOptions: [
        {
          kw: '',
          disable: false,
          selectedClass: ''
        },
        {
          kw: '',
          disable: true,
          selectedClass: 'hideInput'
        },
        {
          kw: '',
          disable: true,
          selectedClass: 'hideInput'
        },
        {
          kw: '',
          disable: true,
          selectedClass: 'hideInput'
        },
        {
          kw: '',
          disable: true,
          selectedClass: 'hideInput'
        },
      ],
      current: 1,
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload()
      }, 20)
    },
    addMoreKw() {
      this.keywordsOptions[this.current].disable = false;
      this.keywordsOptions[this.current].selectedClass = '';
      if (this.current < 5) this.current++;
    },
    getCurrentDate() {
      let date = new Date().getDate()
      let month = new Date().getMonth() + 1
      let year = new Date().getFullYear()
      return `${date}/${month}/${year}`
    },
    clearField() {
      this.marketName = ''
      this.keywords = []
      this.search = ""
      this.submitProcess = false
    },
    submit() {
      // todo: create action > mutation > state login for this function + validate KW before
      if (this.marketName === '') {
        alert('please insert market name')
        return
      }
      if (!this.keywords.length) {
        alert('please enter at least one keyword')
        return
      }
      for (let i = 0; i < this.keywords.length; i++) {
        if (this.keywords[i].replace(/\s/g, '') === '') {
          alert('please enter valid keyword')
          return
        }
      }
      this.submitProcess = true
      let currentDate = this.getCurrentDate()
      let marketData = {
        marketName: this.marketName,
        keywords: this.keywords,
        created: currentDate,
        lastUpdate: null,
        marketplace: 'www.amazon.com'
      }
      serverApi.newMarketToServer(marketData, (res) => {
        this.clearField()
        localStorage.setItem('lastName', JSON.stringify(marketData.marketName))
        localStorage.setItem('lastKeywords', JSON.stringify(marketData.keywords))
      })
      this.$router.push('/market-tracker')
    }
  }
}
</script>

<style lang="sass" scoped>

.hideInput
  display: none

.keyword
  width: auto
  height: 2.5rem
  border: 0.125rem solid #D1C4E9
  border-radius: 10px

.new-keyword
  border: 0.125rem solid #FFF

.instruction
  max-width: 210px
  font-size: 11px

</style>