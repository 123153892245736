import { render } from "./BlurTable.vue?vue&type=template&id=33b401f6&scoped=true"
import script from "./BlurTable.vue?vue&type=script&lang=js"
export * from "./BlurTable.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-33b401f6"

export default script
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnGroup,QBtn,QCard,QPagination,QSeparator});
