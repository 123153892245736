<template>
  <div>
    <div class="text-subtitle1 text-center my-font text-bold">Sales Velocity</div>
    <LineConfig
        class="line-wrapper"
        width="10%"
        :labels="labels"
        :datasets="datasets"
        />
  </div>
</template>

<script>

import {defineComponent} from 'vue';
import LineConfig from '../charts-config/LineConfig';

export default defineComponent({
  name: 'BlurProductLineChart',
  components: {LineConfig},
  data() {
    return {
      datasets: [
        {
          label: "bsr",
          borderColor: "rgb(255, 160, 122)",
          fill: false,
          data: [300, 350, 280, 250, 220, 350, 400, 500, 470, 455, 420, 400],
        },
        {
          label: "sales",
          borderColor: "rgb(241, 196, 15)",
          fill: false,
          data: [25, 50, 75, 100, 50, 60, 70, 60, 44, 30, 17, 33,77,100,120,130,80,66],
        },  {
          label: "reviews",
          borderColor: "rgb(231, 76, 60)",
          fill: false,
          data: [1, 2, 7, 12, 22, 35, 40, 41, 41, 41, 43, 50],
        },
      ],
      labels: ['B0DFASD02','B0DFASD02','B0DFASD02','B0DFASD02'],
    }
  },
})

</script>
<style scoped>

.line-wrapper {
  width: 100%;
  height: 85%;
  max-width: 100%;
  max-height: 100%;
}

</style>7