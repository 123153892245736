<template>
  <q-card class="q-pa-md my-font">
    <p>Please add new keywords, up to 5 may be added.</p>
    <q-input
        class="col q-mx-md text-center text-no-wrap keyword"
        :class="keyword.selectedClass"
        flat
        v-for="(keyword, index) in keywords"
        :key="index"
        :color="keyword.color"
        :label="keyword.label"
        @click="showKeywordOnly(keyword, index)"
    />

    <q-btn
        class="col bg-deep-orange-6 q-ml-md text-left text-no-wrap keyword new-keyword"
        color="white"
        icon-right="add_circle"
        label="NEW KEYWORD"
        glossy
        flat
    />
  </q-card>
</template>

<script>
export default {
  name: "NewMarketKeywords",
  data() {
    return {
      keywords: [ ]
    }
  },
  // methods: {
  //   showKeywordOnly(keyword, key) {
  //     this.setActive(keyword, key)
  //   },
  //   setActive(keyword, index) {
  //     if (keyword.onOff) {
  //       this.keywords[index].selectedClass = 'bg-deep-purple-6'
  //       this.keywords[index].color = 'white'
  //       this.keywords[index].onOff = false
  //     } else {
  //       this.keywords[index].selectedClass = 'bg-white'
  //       this.keywords[index].color = 'deep-purple-6'
  //       this.keywords[index].onOff = true
  //     }
  //   }
  // }
}
</script>

<style lang="sass" scoped>

.keyword
  width: auto
  height: 2.5rem
  border: 0.125rem solid #D1C4E9
  border-radius: 10px

.new-keyword
  border: 0.125rem solid #FFF

</style>