import { render } from "./BlurOverview.vue?vue&type=template&id=ab8a90ea&scoped=true"
import script from "./BlurOverview.vue?vue&type=script&lang=js"
export * from "./BlurOverview.vue?vue&type=script&lang=js"

import "./BlurOverview.vue?vue&type=style&index=0&id=ab8a90ea&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-ab8a90ea"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QAvatar,QItemLabel});
