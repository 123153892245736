<template>
  <div class="bg-grey-3 row blur-effect">
    <div class="q-mb-lg col-12">
      <BlurOverview class="q-mx-lg"/>
    </div>
    <div class="q-mb-lg col-12">
      <BlueCharts class="q-mx-lg"/>
    </div>
    <div class="col-12 ">
      <BlurTable class="q-mx-lg"/>
    </div>
  </div>
</template>

<script>

import BlurOverview from "../../components/blur-template/BlurOverview";
import BlueCharts from "../../components/blur-template/BlurCharts";
import BlurTable from "../../components/blur-template/BlurTable";

export default {
  name: "NewMarketsBlurTemplate",
  components: {BlurTable, BlueCharts, BlurOverview},

}
</script>

<style lang="sass" scoped>

.blur-effect
  filter: blur(3.5px)

</style>