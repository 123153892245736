<template>
  <div>
    <div class="text-subtitle1 text-center pie-wrapper my-font">{{ datasets[0].label }}</div>
    <DoughnutConfig
        :labels="labels"
        :datasets="datasets"/>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import DoughnutConfig from "../charts-config/DoughnutConfig";

export default defineComponent({
  name: "BlurDoughnutChart",
  components: {DoughnutConfig},
  data() {
    return {
      datasets: [
        {
          label: "Top Asins Sales",
          backgroundColor: [
            "rgb(187, 222, 251)",
            "rgb(206, 147, 216 )",
            "rgb(128, 203, 196)",
            "rgb(189, 189, 189)",
          ],
          data: [1, 2, 3, 4],
        },
      ],
      labels: ['', '', '', ''],
    }
    // options: {
    //   legend: {
    //     display: false,
    //         position: 'bottom'
    //   }
    // },
  },
});
</script>

<style>
.pie-wrapper {
  width: 100%;
  height: 100%;
  max-width: 109%;
  max-height: 100%;
}

</style>


